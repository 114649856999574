/* This example requires Tailwind CSS v2.0+ */
import {
  BoltIcon,
  CheckCircleIcon,
  DevicePhoneMobileIcon,
  DeviceTabletIcon,
  GlobeAltIcon,
  RocketLaunchIcon,
  ScaleIcon,
  SparklesIcon,
  CpuChipIcon,
  PencilIcon,
  PowerIcon,
  UserIcon,
  WifiIcon,
  
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Robotic & Automation Software",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: BoltIcon,
    isLink: true,
  },
  {
    name: "R&D Solutions",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: CheckCircleIcon,
  },
  {
    name: "Traceability & Control Systems",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: CpuChipIcon,
  },
  {
    name: "Electirical Project Planning & Design",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: PowerIcon,
  },
  {
    name: "Consultancy Services",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: UserIcon,
  },
  {
    name: "Industrial Network Topology",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: WifiIcon,
  },
];

export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32 lg:py-40" id="why-shift">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:text-center">
          <h2 className="mt-5 text-4xl font-bold leading-8 text-[#ffc658] sm:text-4xl">
            Our Services
          </h2>
          <p className=" text-2xl font-semibold tracking-tight text-gray-400 sm:text-2xl">
    {/* Unusually Smart Solutions*/}
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 font-semibold text-gray-600">
            {/* Unusually Smart Solutions */}
          </p>
        </div>

        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
          <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row"
              >
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-[#ffc658] text-white sm:shrink-0">
                  {feature.isLink ? (
                    <img src="/robotic.svg" className="h-8 w-8 " />
                  ) : (
                    <feature.icon className="h-8 w-8" aria-hidden="true" />
                  )}
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-semibold leading-8 text-gray-900">
                    {feature.name}
                  </p>
                  <p className="mt-2 text-base leading-7 text-gray-600">
                    {/* {feature.description} */}
                  </p>
                </div>
              </div>
            ))}
          </div>
      </div>
    </div>
              <div className="sm:text-center">
          <h2 className="mt-5 text-3xl font-semibold leading-8 text-gray-400 sm:text-3xl">
           Unusually Smart Solutions
          </h2>
              </div>
    </div>



  );
}
