import {
  PhoneIcon,
  MapPinIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";

const supportLinks = [
  {
    name: "Mail",
    href: "mailto:info@shift-engineering.com",
    description: "info@shift-engineering.com",
    icon: PaperAirplaneIcon,
  },
  {
    name: "Location",
    href: "#",
    description:
      "Vezirciftligi Neighbourhood, Fakulte Street NO:140/4A Başiskele/KOCAELİ",
    icon: MapPinIcon,
  },
];

export default function Contact() {
  return (
    <div className="bg-white" id="contact">
      <header className="relative pb-36 bg-blue-gray-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
            alt=""
          />
          <div
            className="absolute inset-0 bg-blue-gray-800 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>

        <div className="relative mt-24 max-w-md mx-auto px-4 pb-32 sm:max-w-3xl sm:px-6 md:mt-32 lg:max-w-7xl lg:px-8">
          <h1 className="pt-4 text-4xl font-extrabold tracking-tight text-black md:text-5xl lg:text-6xl">
            Contact us!
          </h1>
        </div>
      </header>

      <main>
        <div className="bg-blue-gray-50">
          {/* Cards */}
          <section
            className="-mt-32 max-w-md mx-auto relative z-10 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
            aria-labelledby="contact-heading"
          >
            <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
              {supportLinks.map((link) => (
                <div
                  key={link.name}
                  className="flex flex-col bg-white rounded-2xl shadow-xl"
                >
                  <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                    <div className="absolute top-0 p-5 inline-block bg-[#ffc658] rounded-xl shadow-lg transform -translate-y-1/2">
                      <link.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <h3 className="text-xl font-medium text-blue-gray-900">
                      {link.name}
                    </h3>
                    <p className="mt-4 text-base text-blue-gray-500">
                      <a href={link.href}>{link.description}</a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </main>

      <footer className="bg-blue-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-md mx-auto py-12 px-4 sm:max-w-7xl sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img className="h-10" src="/images/shift.webp" alt="Shift Logo" />
              <p className="text-gray-500 text-xl font-semibold">
                Always for the best.
              </p>
            </div>
          </div>
          <div className="mt-12 border-t border-blue-gray-200 pt-8">
            <p className="text-base text-blue-gray-400 xl:text-center">
              &copy; 2022 <span className="text-[#ffc658]">Shift</span>{" "}
              Engineering, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
