const products = [
  {
    id: 1,
    name: "Title",
    price: "$13",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis similique repellendus doloribus accusantium deserunt, ipsam neque animi in culpa fugit! Iure pariatur inventore minus ipsa. Sunt accusantium in aperiam placeat distinctio autem, sint aliquam minus incidunt deleniti labore illum pariatur magni explicabo aut sed, porro voluptate beatae. Fugit cum at ipsum. Explicabo facere incidunt laborum, magni laudantium magnam repudiandae deleniti maiores esse velit asperiores exercitationem, accusantium dolorum animi modi rem libero ex ipsam autem voluptatum quam praesentium debitis? Sit aliquam dolorem ipsam perferendis eum enim dolor molestiae nesciunt ut numquam quidem error ipsa omnis officiis quis, voluptatem, sint doloribus est?",
    imageSrc:
      "https://bernardmarr.com/img/10%20Amazing%20Examples%20of%20Robotic%20Process%20Automation%20in%20Practice.png",
    imageAlt: "Shift Robotics Image",
  },
  {
    id: 2,
    name: "Title",
    price: "$64",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis similique repellendus doloribus accusantium deserunt, ipsam neque animi in culpa fugit! Iure pariatur inventore minus ipsa. Sunt accusantium in aperiam placeat distinctio autem, sint aliquam minus incidunt deleniti labore illum pariatur magni explicabo aut sed, porro voluptate beatae. Fugit cum at ipsum. Explicabo facere incidunt laborum, magni laudantium magnam repudiandae deleniti maiores esse velit asperiores exercitationem, accusantium dolorum animi modi rem libero ex ipsam autem voluptatum quam praesentium debitis? Sit aliquam dolorem ipsam perferendis eum enim dolor molestiae nesciunt ut numquam quidem error ipsa omnis officiis quis, voluptatem, sint doloribus est?",
    imageSrc:
      "https://hillselectricalltd.co.uk/wp-content/uploads/2020/04/Robotics-vs-automation-%E2%80%93-whats-the-difference-Hills-Electrical-Group.jpg",
    imageAlt: "Shift Robotics Image",
  },
  {
    id: 3,
    name: "Title",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis similique repellendus doloribus accusantium deserunt, ipsam neque animi in culpa fugit! Iure pariatur inventore minus ipsa. Sunt accusantium in aperiam placeat distinctio autem, sint aliquam minus incidunt deleniti labore illum pariatur magni explicabo aut sed, porro voluptate beatae. Fugit cum at ipsum. Explicabo facere incidunt laborum, magni laudantium magnam repudiandae deleniti maiores esse velit asperiores exercitationem, accusantium dolorum animi modi rem libero ex ipsam autem voluptatum quam praesentium debitis? Sit aliquam dolorem ipsam perferendis eum enim dolor molestiae nesciunt ut numquam quidem error ipsa omnis officiis quis, voluptatem, sint doloribus est?",
    imageSrc:
      "https://cdn.thomasnet.com/insights-images/1e310267-8fa4-41d7-aca5-0167019b584d/750px.png",
    imageAlt: "Shift Robotics Image",
  },
  // More products...
];

export default function WhoWeAre() {
  return (
    <div className="bg-white" id="who-we-are">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 id="products-heading" className="sr-only">
          Products
        </h2>

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">
          {products.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-w-2 sm:aspect-h-3">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="w-full h-full object-center object-contain group-hover:opacity-75"
                />
              </div>
              <div className="mt-4 flex items-center justify-between text-2xl font-medium text-gray-900">
                {/* <h3>{product.name}</h3> */}
              </div>
              <p className="mt-1 text-sm italic text-gray-500">
                {/* {product.description} */}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
