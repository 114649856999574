import "./App.css";
import Contact from "./components/contact";
import Features from "./components/features";
import Welcome from "./components/welcome";
import WhoWeAre from "./components/whoWeAre";

function App() {
  return (
    <div>
      <Welcome />
      <Features />
      <WhoWeAre />
      <Contact />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
